import { Cart } from '../domain';
import isFunction from './isFunction';
import { logError } from './logging';

// This function is called after a gift is added on any page that is not /cart page.
// It tries to use window API methods / dispatch events from different themes / slider cart apps
// to update the drawer carts in real time without the need of a reload.
// Lastly we try to perform an update of Web Component slider carts using their internal API.
// Custom function is also available for specific stores window.GIFTBOX_CUSTOM_UPDATE_CART
const updateDrawerCarts = (updatedCart: Cart) => {
  // Custom update cart callback
  if (isFunction(window.GIFTBOX_CUSTOM_UPDATE_CART)) {
    return window.GIFTBOX_CUSTOM_UPDATE_CART(updatedCart);
  }

  // Drawer Carts window method calls
  try {
    if (isFunction(window.wetheme?.updateCartDrawer)) {
      window.wetheme.updateCartDrawer(updatedCart);
    }

    if (isFunction(window.refreshCart)) {
      window.refreshCart(updatedCart);
    }

    if (isFunction(window.ajaxCart?.load)) {
      window.ajaxCart.load();
    }

    if (isFunction(window.monster_setCartItems) && updatedCart?.items?.length) {
      window.monster_setCartItems(updatedCart.items);
    }

    if (isFunction(window.updateCartDrawerUI)) {
      window.updateCartDrawerUI();
    }

    if (
      isFunction(window.cart?.updateCart) &&
      isFunction(window.cart?.toggleCartDropdown)
    ) {
      window.cart.updateCart();
      window.cart.toggleCartDropdown();
    }

    if (isFunction(window.cart?.getCart)) {
      window.cart.getCart();
    }

    if (isFunction(window.SLIDECART_UPDATE)) {
      window.SLIDECART_UPDATE();
    }

    if (isFunction(window.HS_SLIDE_CART_UPDATE)) {
      window.HS_SLIDE_CART_UPDATE();
    }

    if (isFunction(window.store?.getCart)) {
      window.store.getCart();
    }

    if (isFunction(window.monster_setCartItems)) {
      window.monster_setCartItems(updatedCart.items);
    }

    if (isFunction(window.upcartRegisterAddToCart)) {
      window.upcartRegisterAddToCart();
    }

    if (isFunction(window.CartJS?.cart?.update)) {
      window.CartJS.cart.update(updatedCart);
    }

    if (isFunction(window.theme?.cart?.getCart) && window.theme?.CartDrawer) {
      window.theme.cart.getCart().then(function () {
        new window.theme.CartDrawer();
      });
    }

    if (isFunction(window?.theme?.updateCartSummaries)) {
      window.theme.updateCartSummaries();
    }

    if (isFunction(window?.theme?.cart?.updateAllHtml)) {
      window.theme.cart.updateAllHtml();
    }

    if (isFunction(window.monster_refresh)) {
      window.monster_refresh();
    }

    if (isFunction(window.MinimogTheme?.Cart?.renderNewCart)) {
      window.MinimogTheme.Cart.renderNewCart();
    }

    if (
      isFunction(window.theme?.miniCart?.updateElements) &&
      isFunction(window.theme?.miniCart?.generateCart)
    ) {
      window.theme.miniCart.updateElements();
      window.theme.miniCart.generateCart();
    }
  } catch (e) {
    logError(`Cart update failed via window method- ${e}`);
  }

  // Custom Events that trigger cart rendering
  try {
    document.dispatchEvent(new CustomEvent('cart:build'));
    document.dispatchEvent(new CustomEvent('cartdrawer:refresh'));
    document.documentElement.dispatchEvent(
      new CustomEvent('cart:refresh', {
        bubbles: true,
      }),
    );
    document.dispatchEvent(new CustomEvent('dispatch:cart-drawer:refresh'));
  } catch (e) {
    logError(`Updating cart via custom event failed - ${e}`);
  }

  // Section update cart - web components
  try {
    const elementSelectors = [
      'cart-drawer',
      'cart-notification',
      window.GIFTBOX_CUSTOM_DRAWER_CART_WEB_COMPONENT || '',
    ];
    const cartElements = document.querySelectorAll(
      elementSelectors
        .filter((elementSelector) => elementSelector.length)
        .join(','),
    );
    if (isFunction(window.fetchConfig) && cartElements.length) {
      (cartElements as NodeListOf<CustomCartElement>).forEach((cartElement) => {
        if (
          isFunction(cartElement.getSectionsToRender) &&
          isFunction(cartElement.renderContents)
        ) {
          const sections = cartElement
            .getSectionsToRender()
            .map((section) => section.id);
          const body = JSON.stringify({
            sections: sections,
            sections_url: window.location.pathname,
          });
          fetch(`/cart/update`, {
            ...window.fetchConfig(),
            ...{
              body,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              cartElement.renderContents(response);
            });
        }
      });
    }
  } catch (e) {
    logError(`Updating cart via section API failed - ${e}`);
  }
};

export default updateDrawerCarts;
